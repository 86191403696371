export default {
    init() {
        // JavaScript to be fired on all pages

        // Jquery-match-height
        $(function () {
            setTimeout(function () {
                $('.home .testimonial__content').matchHeight();
            }, 500);
        });

        // // Slick slider example
        // $('.slick__slider').slick({
        //     dots: true,
        //     infinite: true,
        //     speed: 300,
        //     slidesToShow: 1,
        //     slidesToScroll: 1,
        //     responsive: [
        //         {
        //             breakpoint: 1024,
        //             settings: {

        //             },
        //         },
        //     ],
        // });

        // $(document).ready(function() {
        //     $(".fancybox").fancybox();
        // });

        $('.slick-slider').css('opacity', '1');

        animateNav($(window).scrollTop());
        $(window).on('scroll', function () {
            let scroll = $(window).scrollTop();
            animateNav(scroll);
        });

        function animateNav(scroll) {
            let className = 'is-scrolled';
            (scroll > 0) ? $('.home').addClass(className) : $('.home').removeClass(className);
        }


        if (!$('body.home')[0]) {
            $('body').addClass('is-scrolled');
        }

        $('#scrollToSection').click(function () {
            let targetElement = $(this).attr('data-scroll-target');
            if (targetElement) {
                $('html, body').animate({
                    scrollTop: $('#' + targetElement).offset().top}, 'slow');
            }
        });

        $('.navbar-toggler').click(function () {
            $('body').toggleClass('fullscreennav--show');
        });

        $('.testimonials__slider').slick({
            dots: false,
            infinite: true,
            speed: 500,
            fade: true,
            cssEase: 'linear',
            nextArrow: '<button class="slick-next slick-arrow" aria-label="Next" type="button">' +
                '<svg width="20" height="53" aria-hidden="true" focusable="false" data-prefix="far" data-icon="angle-right" class="svg-inline--fa fa-angle-right fa-w-6" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512"><path fill="#4A6A93" d="M187.8 264.5L41 412.5c-4.7 4.7-12.3 4.7-17 0L4.2 392.7c-4.7-4.7-4.7-12.3 0-17L122.7 256 4.2 136.3c-4.7-4.7-4.7-12.3 0-17L24 99.5c4.7-4.7 12.3-4.7 17 0l146.8 148c4.7 4.7 4.7 12.3 0 17z"></path></svg>\n' +
                '</button>',
            prevArrow: '<button class="slick-prev slick-arrow" aria-label="Previous" type="button">' +
                '<svg width="20" height="53" aria-hidden="true" focusable="false" data-prefix="far" data-icon="angle-left" class="svg-inline--fa fa-angle-left fa-w-6" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512"><path fill="#4A6A93" d="M4.2 247.5L151 99.5c4.7-4.7 12.3-4.7 17 0l19.8 19.8c4.7 4.7 4.7 12.3 0 17L69.3 256l118.5 119.7c4.7 4.7 4.7 12.3 0 17L168 412.5c-4.7 4.7-12.3 4.7-17 0L4.2 264.5c-4.7-4.7-4.7-12.3 0-17z"></path></svg>' +
                '</button>',
        });

        $('.features__slider').slick({
            dots: false,
            infinite: true,
            speed: 500,
            fade: false,
            cssEase: 'linear',
            autoplay: true,
            slidesToShow: 1,
            swipeToSlide: true,
            arrows:false,
            variableWidth: true,
        });

        $('.profile__slider').slick({
            dots: false,
            infinite: true,
            speed: 500,
            fade: true,
            cssEase: 'linear',
            nextArrow: '<button class="slick-next slick-arrow" aria-label="Next" type="button">' +
                '<svg width="20" height="53" aria-hidden="true" focusable="false" data-prefix="far" data-icon="angle-right" class="svg-inline--fa fa-angle-right fa-w-6" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512"><path fill="#4A6A93" d="M187.8 264.5L41 412.5c-4.7 4.7-12.3 4.7-17 0L4.2 392.7c-4.7-4.7-4.7-12.3 0-17L122.7 256 4.2 136.3c-4.7-4.7-4.7-12.3 0-17L24 99.5c4.7-4.7 12.3-4.7 17 0l146.8 148c4.7 4.7 4.7 12.3 0 17z"></path></svg>\n' +
                '</button>',
            prevArrow: '<button class="slick-prev slick-arrow" aria-label="Previous" type="button">' +
                '<svg width="20" height="53" aria-hidden="true" focusable="false" data-prefix="far" data-icon="angle-left" class="svg-inline--fa fa-angle-left fa-w-6" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512"><path fill="#4A6A93" d="M4.2 247.5L151 99.5c4.7-4.7 12.3-4.7 17 0l19.8 19.8c4.7 4.7 4.7 12.3 0 17L69.3 256l118.5 119.7c4.7 4.7 4.7 12.3 0 17L168 412.5c-4.7 4.7-12.3 4.7-17 0L4.2 264.5c-4.7-4.7-4.7-12.3 0-17z"></path></svg>' +
                '</button>',
        });

    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};
